<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <!-- <div class="my-order01">
          <span>首页</span>
        </div> -->
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="exhibit" :userId="userId"/>
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right" style="width:100%">
            <router-link :to="{name: 'ExbitAdd', params: {userId: userId}}">
              <a-button type="primary">
                {{ $t('user.add') }}
              </a-button>
            </router-link>
            <a-table ref="table" :row-key="record=>{return record.productId}" :columns="columns" :data-source="list" :pagination="pagination" @change="onChange1">
                <span slot="action" slot-scope="text, record">
                  <router-link :to="{name: 'ExbitEdit', params: {id: record.productId, userId: userId, categoryId1: record.categoryId1, categoryId2: record.categoryId2} }">{{ $t('user.edit') }}</router-link>
                  <a-divider type="vertical" />
                  <router-link :to="{name: 'ExbitEditThree', params: {id: record.productId, userId: userId} }">{{ $t('user.edit3d') }}</router-link>
                  <a-divider type="vertical" />
                  <a @click="deleteEmply(record.productId,record.status)">{{record.status == 1 ? $t('user.off') : $t('user.on')}}</a>
                </span>
            </a-table>
          </a-card>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
  .ant-card-body{
    text-align: left;
  }
  .ant-table-body{
    margin-top: 10px;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  inject: ['reload'], // 注入依赖
  name: 'UsersExhibit',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
      list: [],
      page: 1,
      userId: this.$route.params.userId,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showTotal: (total, range) => {
          return (<div>共 <span>{total}</span> 条</div>)
        },
        onShowSizeChange: (current, pageSize) => {
          this.pageSize = pageSize
        }
      },
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('user.number'),
          dataIndex: 'productId',
        },
        {
          title: this.$t('user.product.name'),
          dataIndex: 'productName',
        },
        {
          title: this.$t('user.product.category-one'),
          dataIndex: 'categoryOneName',
        },
        {
          title: this.$t('user.product.category-two'),
          dataIndex: 'categoryTwoName',
        },
        {
          title: this.$t('user.keywords'),
          dataIndex: 'keywords',
        },
        {
          title: this.$t('user.product.max-price'),
          dataIndex: 'maxPrice',
        },
        {
          title: this.$t('user.product.min-price'),
          dataIndex: 'minPrice',
        },
        {
          title: this.$t('user.product.min-order'),
          dataIndex: 'minOrder',
        },
        {
          title: this.$t('user.operation'),
          scopedSlots: { customRender: 'action' }
        },
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 改变每页数量时更新显示
    onChange1 (pagination) {
      this.pagination.defaultCurrent = pagination.current
      this.pagination.defaultPageSize = pagination.pageSize
      this.getList()
    },
    // 获取账号信息
    getList () {
       const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize
      }
       this.$common.fetchList('/user/product/lists', params).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result.lists
          this.list.forEach(record => {
    console.log({id: record.productId, userId: this.userId, categoryId1: record.categoryId1, categoryId2: record.categoryId2})

          })
          console.log(this.list)
          this.pagination.total = result.totalInfo.total_items
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    //删除产品
    deleteEmply (id,status) {
      if (status == 1){
        this.$common.fetchList('/user/product/delete/'+id, {}).then(data => {
        if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
        }
        }).catch(() => {
          this.$notification.error({
            message: '错误',
            description: '请求信息失败，请重试'
          })
        })
      } else {
        this.$common.fetchList('/user/product/resume/'+id, {}).then(data => {
        if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
        }
        }).catch(() => {
          this.$notification.error({
            message: '错误',
            description: '请求信息失败，请重试'
          })
        })
      }
      
    }
  }
}
</script>